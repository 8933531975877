import { LineUser } from "../domains/LineUser";
import { useQuery } from "react-query";
// import { delay } from "../utils/delay";

export function useGetLineUser() {
  return useQuery<LineUser | undefined>(["liff"], async () => {

    //TODO: mock
    // if (process.env.NODE_ENV === "development") {
    //   // await delay(1000);
    //   return {
    //     display_name: "foobar",
    //     "line-user-id": "U11eedee1202e5051762b3814c0f4cae8",
    //     "line-access-token": "eyJhbGciOiJIUzI1NiJ9.GL9LD_BQOuHWwbekyN9kvOLgu6NM4m1lxYkUr-VRB0mRE1UMT50PFUxPvtSE2ZLBsOOIDbheXWY9_kHyL_o6f3MnxwQ3hwYz3o6deyfWWAMajwwkk0FXMZLMnhZzO14_ym-ceGnLUvo5WSbcVza2JfE1BfrkBgFVgKSReM7_vpA.tTzQirJJxq0UcYbh44Zk658zEeU-KVVa-Zq_Qds-ZMo",
    //     friend_flag: true
    //   };
    // }
    return await liff
      .init({ liffId: process.env.REACT_APP_LIFF_ID! })
      .then(() => {
        if (process.env.NODE_ENV !== "development" && !liff.isLoggedIn()) {
          liff.login({});
        }
      })
      .then(() => Promise.all([liff.getProfile(), liff.getFriendship()]))
      .then(([profile, friendship]) => {
        return {
          display_name: profile.displayName,
          "line-access-token": liff.getAccessToken(),
          "line-user-id": profile.userId,
          friend_flag: friendship.friendFlag
        };
      })
      .catch(() => {
        return undefined;
      });
  });
}
