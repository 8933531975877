import React from "react";
import { mount, compose, withView, lazy } from "navi";
import { View } from "react-navi";
import FormClosedPage from "./closed";
import FormInputPage from "./input";

export default compose(
  withView(() => (
    <FormPage>
      <View />
    </FormPage>
  )),
  mount({
    "/": FormClosedPage,
    "/form": FormInputPage,
    "/applied": lazy(() => import("./applied")),
  })
);

interface FormPageProps {
  children: React.ReactNode;
}

const FormPage = ({ children }: FormPageProps) => {
  return <div className="FormPage">{children}</div>;
};
