import React from "react";
import classNames from "classnames";

interface ISubmitButtonFieldProps {
  submitting?: boolean;
  disabled: boolean;
}

export const SubmitButtonField = ({
  submitting,
  disabled,
}: ISubmitButtonFieldProps) => {
  return (
    <div className="field">
      <div className="control">
        <button
          className={classNames(
            "button is-link is-fullwidth is-medium has-text-weight-bold",
            {
              "is-loading": submitting,
            }
          )}
          type="submit"
          disabled={disabled}
        >
          応募する
        </button>
      </div>
    </div>
  );
};
