import React from "react";
import classNames from "classnames";
import { mount, route } from "navi";
import { Helmet } from "react-navi-helmet-async";
// Hooks
import { useGetLineUser } from "../../../hooks/useGetLineUser";
import { useGetStatus } from "../../../hooks/useGetStatus";
// Components
import { TitleField } from "../../../components/forms/TitleField";
import { Redirect } from "../../../components/utils/Redirect";
import { Status } from "../../../domains/Status";

export default mount({
  "/": route({
    getView: () => <FormClosedPage />,
  }),
});

const FormClosedPage = () => {
  const os = liff.getOS();
  const { data: user } = useGetLineUser();
  const { data: status } = useGetStatus(user);

  if (status?.status === Status.APPLIED) {
    return <Redirect url="/form/applied" />;
  }
  return (
    <div>
      <Helmet>
        <title>応募を締め切りました | 第１回学生アナウンス大賞</title>
      </Helmet>
      <div className={classNames("Page FormAppliedPage", `is-${os}`)}>
        <section className="section">
          <div className="container">
            <TitleField
              title=" 第１回学生アナウンス大賞"
              subtitle="書類提出フォーム"
            />
            <hr />
            <div className="field">
              2月28日をもちまして、書類提出の受付を終了いたしました。
            </div>
            <hr />
            <div className="field">
              <div className="buttons is-centered">
                <a
                  href={process.env.REACT_APP_OFFICIAL_SITE_URL!}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button has-text-weight-bold is-text"
                >
                  公式サイト
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
